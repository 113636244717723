import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from 'redux';
import reduxThunk from 'redux-thunk';
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage/session";
import App from './components/App';
import reducers from './reducers';
import { PersistGate } from 'redux-persist/integration/react';
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig, protectedResources } from "./authConfig";


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders. 
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const msalInstance = new PublicClientApplication(msalConfig);
//  const pca = new PublicClientApplication(msalConfig);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();

fetch('%PUBLIC_URL%/authConfig.json').then(response => {
  console.log("Auth", response)
  response.json().then(auth => {
      this.setState({auth: auth});
  })
}) 

if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

function loginRedirect() {
  try {
    const loginRequest = {
      scopes: protectedResources.apiTodoList.scopes,
    };
    msalInstance.loginRedirect(loginRequest);
  } catch (err) {
    console.log(err);
  }
}

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    // console.log("throw an event", event.payload)
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
    
    // store.subscribe(() => {
    // signInMsal(store.getState());
    // });
    store.dispatch({type:'MSAL_LOGIN_SUCCESS', payload : event.payload})

    // this.props.signInMsal(event.payload)
  } else if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
    if (event.error?.name === "InteractionRequiredAuthError" && window.location.pathname.startsWith(protectedResources.quizApi.path)) {
      loginRedirect();
    } else {
      console.log("ACQUIRE_TOKEN_FAILURE");
    }
  } else if (event.eventType === EventType.LOGIN_FAILURE) {
    console.log(JSON.stringify(event));
    if (event.error?.name === "BrowserAuthError" && window.location.pathname.startsWith(protectedResources.quizApi.path)) {
      loginRedirect();
    } else {
      console.log("LOGIN FAILURE");
    }
  } else {
    console.log("Callback finished");
  }
 });

msalInstance
  .handleRedirectPromise()
  .then(() => {
    if (window.location.pathname.startsWith(protectedResources.quizApi.path)) {
      const account = msalInstance.getActiveAccount();
      if (!account) {
        loginRedirect();
      }
    }
  })
  .catch((err) => {
    console.log(err);
  });


const persistConfig = {
  key: 'root',
  storage: storage,
  blacklist: ['authMsal']
};
const persistedReducer = persistReducer(persistConfig, reducers);
const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(reduxThunk))
);
const newPersistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={newPersistor}>
      <BrowserRouter>
        <App instance={msalInstance} store={store} />
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);