import { combineReducers } from "redux";
import { reducer as formReducer } from 'redux-form';

import authReducer from './authReducer';
import homeReducer from "./homeReducer";
import isolatesReducer from './isolatesReducer';
import { SIGNOUT_SUCCESS } from "../actions/types";
import userTreeReducer from "./userTreeReducer";
import qcReducer from "./qcReducer";
import usersReducer from "./usersReducer";
import userDetailReducer from "./userDetailReducer";
import analyticsReducer from "./anayticsReducer";

const appReducer = combineReducers({
    auth: authReducer,
    home: homeReducer,
    isolates: isolatesReducer,
    form: formReducer,
    userTree: userTreeReducer,
    userQC: qcReducer,
    userAccount: usersReducer,
    userDetail: userDetailReducer,
    analyticsData: analyticsReducer    
});

const rootReducer = (state, action) => {
    if (action.type === SIGNOUT_SUCCESS) {
        localStorage.removeItem('persist:root');
        state = undefined;
        return appReducer(undefined, action);
    }
    return appReducer(state, action);
}

export default rootReducer;