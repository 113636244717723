import userQC from "../apis/userQC";
import { GET_USER_QC } from "./types";
import eventBus from "../common/EventBus";
import { protectedResources } from "../authConfig";
import { msalInstance } from "../index";

const getToken = async () => {
    const account = msalInstance.getActiveAccount();

    if (!account) {
        eventBus.dispatch("logout");
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }

    const response = await msalInstance.acquireTokenSilent({
        account: account,
        ...protectedResources.apiTodoList.scopes
    });

    return response.accessToken;
}

export const getUserQC = () => {
    return async (dispatch, getState) => {
        const token = await getToken();

        try {
            const config = {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                data: {},
            };

            const response = await userQC.get(
                '/qc',
                config,
            );

            dispatch({
                type: GET_USER_QC,
                payload: response.data,
            });
        }
        catch (error) {
            console.log(error);
            if (error) {
                eventBus.dispatch("logout");
              }
        }
    }
};