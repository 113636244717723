import userAccount from "../apis/userAccount";
import { GET_USER, UPDATE_PASSWORD} from "./types";
import eventBus from "../common/EventBus";
import { protectedResources } from "../authConfig";
import { msalInstance } from "../index";

const getToken = async () => {
    const account = msalInstance.getActiveAccount();

    if (!account) {
        eventBus.dispatch("logout");
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }

    const response = await msalInstance.acquireTokenSilent({
        account: account,
        ...protectedResources.apiTodoList.scopes
    });

    return response.accessToken;
}

export const getUser = () => {
    return async (dispatch, getState) => {
        const token = await getToken();

        const config = {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            data: {}
        };
        await userAccount.get(
            '/show_user',
            config,
        ).then(response => {
            dispatch({
                type: GET_USER,
                payload: response.data,
            });
        }).catch(error => {
            console.log("ERROR",error)
            if (error) {
                eventBus.dispatch("logout");
              }
        });
    }
};

export const updatePassword = () => {
    return async (dispatch, getState) => {
        const token = await getToken();

        const config = {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            data: {}
        };
        await userAccount.patch(
            '/update_password',
            config,
        ).then(response => {
            console.log('Response', response)
            dispatch({
                type: UPDATE_PASSWORD,
            });
        }).catch(error => {
            console.log("ERROR",error)
            if (error) {
                eventBus.dispatch("logout");
              }
        });
    }
};