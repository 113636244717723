import isolates from "../apis/isolates";
import { GET_ISOLATES } from "./types";
import eventBus from "../common/EventBus";
import { protectedResources } from "../authConfig";
import { msalInstance } from "../index";
const sanitizeHtml = require('sanitize-html');

const getToken = async () => {
    const account = msalInstance.getActiveAccount();

    //if (!account) {
        //eventBus.dispatch("logout")
        //throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    //}

    const response = await msalInstance.acquireTokenSilent({
        account: account,
        ...protectedResources.apiTodoList.scopes
    });

    return response.accessToken;
}


export const getUserIsolates = (currIsolates, metadata) => {
    //const currIsolates = sanitizeHtml(currIsolates);
    //const metadata = sanitizeHtml(metadata);
    if (metadata) {
        const customKeys = metadata[0];
        const newKeys = [];

        // for (var i = 0; i < metadata.length; i++) {
        //     for (var j = 0; j < currIsolates.length; j++) {
        //         if (metadata[i].data['Id'] === currIsolates[j]['record_id']) {
        //             for (let ckey of customKeys) {
        //                 if (ckey.toLowerCase() !== 'id') {
        //                     currIsolates[j][ckey] = metadata[i].data[ckey];
        //                 }
        //             }
        //         }
        //     }
        // }

        for (let i = 0; i < metadata.length; i++) {
            for (let j = 0; j < currIsolates.length; j++) {
                if (metadata[i][0] == currIsolates[j]['record_id']) {
                    for (let [index,ckey] of customKeys.entries()) {
                        if (ckey.toLowerCase() !== 'id') {
                            currIsolates[j][ckey] =  sanitizeHtml(metadata[i][index]);
                        }
                    }
                }
            }
        }

        customKeys.forEach(k => {
            //const kClean = sanitizeHtml(k);
            if (k.toLowerCase() !== 'id') {
                console.log(k);
                console.log(sanitizeHtml(k));
                newKeys.push(k);
                currIsolates.map(x => {
                    if (!Object.keys(x).includes(k)) {
                        x[k] = "";
                    }
                    return true;
                })

                // console.log("CURRISOLATES", currIsolates[0])
            }
        });

        return async (dispatch) => {
            try {
                dispatch({
                    type: GET_ISOLATES,
                    payload: currIsolates,
                    uploadStatus: true,
                    newColumns: newKeys,
                    error: null
                });
            }
            catch (error) {
                // dispatch({
                //     type: GET_ISOLATES_ERROR,
                //     error: error
                // });
                // console.log(error);
                //if (error) {
                //    eventBus.dispatch("logout");
                //  }
            }
        }
    } else {
        return async (dispatch, getState) => {
            const accessToken = await getToken();

            try {
                const config = {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    },
                };

                const response = await isolates.get(
                    '/metadata',
                    config,
                );

                dispatch({
                    type: GET_ISOLATES,
                    payload: response.data,
                    uploadStatus: false,
                    newColumns: null,
                    error: null
                });
            }
            catch (error) {
                // console.log(error);
                // dispatch({
                //     type: GET_ISOLATES_ERROR,
                //     error: error
                // });
                //if (error) {
                //    eventBus.dispatch("logout");
                //  }
            }
        }
    }
};
