import MainComponent from '../MainComponent';
import { connect } from "react-redux";
import M from "materialize-css";
import "materialize-css/dist/css/materialize.min.css";
import userAccount from '../../apis/userAccount';
import { getUser } from "../../actions/userDetailAction";
import { signOut } from "../../actions/authActions";
import { SIGNOUT_SUCCESS } from "../../actions/types";
import { Redirect } from "react-router";

const validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach(
        // if we have an error string set valid to false
        (val) => val.length > 0 && (valid = false)
    );
    return valid;
}


class ChangePwdModal extends MainComponent {

    constructor(props) {
        super(props);
        this.state = {
            oldPwd: null,
            newPwd: null,
            errors: {
                oldPwd: '',
                newPwd: '',
            },
            updateError: '',
            updateSuccess: '',
            navigate: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.redirectToLogin = this.redirectToLogin.bind(this);
    }


    async componentDidMount() {

        const options = {
            inDuration: 250,
            outDuration: 250,
            opacity: 0.5,
            dismissible: true,
            startingTop: "4%",
            endingTop: "10%"
        };
        M.Modal.init(this.Modal, options);
        M.AutoInit();
        await this.props.getUser();
    }

    handleChange(event) {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        switch (name) {
            case 'oldPwd':
                errors.oldPwd =
                    value.length < 8
                        ? 'Old Passsword should be more than 8 characters long!'
                        : '';
                break;
            case 'newPwd':
                errors.newPwd =
                    value.length < 8
                        ? 'New Password should be more than 8 characters long!'
                        : '';
                break;
            default:
                break;
        }

        this.setState({ errors, [name]: value })
    }

    handleSubmit = async (e) => {
        let formValues = {}
        e.preventDefault();
        if (validateForm(this.state.errors)) {
            formValues = {
                "old_password" : this.state.oldPwd,
                "new_password" : this.state.newPwd
            }
            this.updatePassword(formValues)
        } else {
            console.error('Invalid Form')
        }
    }
    
    redirectToLogin = () => {
        this.setState({
            navigate: true
        })
        this.props.signOut()
        return (dispatch, getState) => {
            dispatch({
                type: SIGNOUT_SUCCESS,
            }) 
        }
        
    }

    updatePassword(formValues) {
        const { auth } = this.props;
        const config = {
            headers: {
                'Authorization': `Bearer ${auth.token}`,
            }
        };
        userAccount.patch(
            '/update_password',
            formValues,
            config
        ).then(res => {
            if(res.status === 200) { 
                this.setState({ 'updateSuccess': res.data.msg , 'updateError': ''});
                var Modalelem = document.querySelector('#changePassword');
                var instance = M.Modal.init(Modalelem);
                instance.close();

                var okModal = document.querySelector("#okModal");
                var okinstance = M.Modal.init(okModal);
                okinstance.open();
            }
        }).catch(error => {
            console.log('error', error);
            if(error){
                this.setState({ 'updateError': error.response.data.msg });
            }
        })
    }
    render() {

        const { auth} = this.props;
        const { errors, updateError, updateSuccess, navigate } = this.state;

        if (navigate) {
            return <Redirect to="/login"/>;
        }

        return (
            <div className="row center">
                <div className="card-content grey-text text-darken-3">
                <h5 className="center"><a href="#changePassword" className="btn btn-large modal-trigger">Change Password</a></h5>
                </div>
                <div id="changePassword" className="modal card-panel">
                        <h5 className="modal-close">&#10005;</h5>
                        <div className="modal-content center">
                            <div className="card-action #0d47a1 blue darken-4 white-text">
                                <h4 id="title">Update your credentials</h4>
                            </div>
                        </div>
                        <form
                            // onSubmit={this.props.handleSubmit(this.onSubmit)}
                            className="ui form error"
                            id="updateForm"
                            onSubmit={this.handleSubmit}>
                            <div className="card-content">
                                <div className="form-field">
                                    <label htmlFor="email" className="center">Email address</label>
                                    <h6>{auth.email}</h6>
                                </div>
                                <br />
                                <div className="form-field">
                                    <label htmlFor="password">Old password </label>
                                    <input
                                        id="oldPwd"
                                        name="oldPwd"
                                        type="password"
                                        required
                                        onChange={this.handleChange}
                                    />
                                    {errors.oldPwd.length > 0 &&
                                        <div className="red-text center">{errors.oldPwd}</div>}
                                </div>
                                <br />
                                <div className="form-field">
                                    <label htmlFor="password">New password</label>
                                    <input
                                        id="newPwd"
                                        name="newPwd"
                                        type="password"
                                        required
                                        onChange={this.handleChange}
                                    />
                                    {errors.newPwd.length > 0 &&
                                        <div className="red-text center">{errors.newPwd}</div>}
                                </div>
                                <br />
                                <div className="form-field center">
                                    <button
                                        type="submit"
                                        className="btn-large #0d47a1 darken-4 waves-effect waves-dark">
                                        Update
                                    </button>

                                    <div className="red-text center">
                                        {
                                            updateError? 
                                                <p>{updateError}</p> : null
                                        }
                                    </div> 

                                    <div className="green-text center">
                                        {
                                            updateSuccess ?
                                                <p>{updateSuccess}</p> : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div id="okModal" className="modal card-panel">
                        <h5 className="modal-close">&#10005;</h5>
                        <div className="modal-content center">
                            <div className="card-action #0d47a1 blue darken-4 white-text">
                                <h4 id="title">Your Password has been updated</h4>
                            </div>
                            <p>Please login again with new password after clicking ok</p>
                            <button className="btn-large #0d47a1 darken-4 waves-effect waves-dark" onClick={this.redirectToLogin}>
                             OK
                            </button>
                        </div>
                    </div>

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
  }  

// const mapDispatchToProps = (dispatch) => {
//     return {
//       signOut: () => dispatch(signOut())
//     }
//   }

export default connect(
    mapStateToProps,
    { getUser, signOut }
  )(ChangePwdModal);