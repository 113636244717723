import React from 'react';

import MainComponent from '../MainComponent';

import { connect } from 'react-redux';
import { Redirect } from "react-router";

// import MaterialTable from "material-table";
// Import plotly
import Plot from "react-plotly.js";

import { getAnalytics } from "../../actions/analyticsActions";

class DataAnalytics extends MainComponent {

    constructor(props) {
        super();
        this.state = { data: null };
    }

    async componentDidMount() {
        this.mounted = true;
        const { auth, analyticsData } = this.props
        if(auth.email){
            const analytics = await this.props.getAnalytics();
            this.setState({ data: analytics });
            console.log("Anayltics", analytics)
        }
        console.log("Data", this.state.data)
    }

    // Clearing datatable and destroying the ref element on unmount
    componentWillUnmount() {
        this.mounted = false;
    }

    
    render() {
        const { auth, analyticsData } = this.props;
        const year = [], values = [], type = [], zerovalues=[];
        const clinical_type=[], non_clinical_type = [], year_clin = [],year_non_clin=[];
        const sttype = [];
        var dictionary = {}, stDictionary={},clinical_type_by_year={},non_clinical_type_by_year={},proj_client={};

        if (!auth.email) return <Redirect to='/login' />
        console.log("Log", analyticsData)

        if(!analyticsData)
            return (
                <main>
                    <div className="container center">
                        <div className="progress" style={{ height: "10px", borderRadius: "10px" }}>
                            <div className="indeterminate"></div>
                        </div>
                        <div>
                            <h4>Loading Analytics data....</h4>
                        </div>
                    </div>
                </main>
            )
        else 
              // Map dataanaytics data and create independent list items for Bar plots
                analyticsData.map((row, index) => {
                zerovalues.push(row.value);

                if(row.year > 1990){
                    if (dictionary[row.year] > 0) {
                        dictionary[row.year] = dictionary[row.year] + row.value;
                    }
                    else {dictionary[row.year] = row.value;}

                    if (stDictionary[row.sttype] > 0) {
                        stDictionary[row.sttype] = stDictionary[row.sttype] + row.value;
                    }
                    else {stDictionary[row.sttype] = row.value;}


                    //if (proj_client[row.projclient] > 0) {
                    //    proj_client[row.projclient] = proj_client[row.projclient] + row.value;
                    //}
                    //else {proj_client[row.projclient] = row.value;}

                    year.push(row.year);
                    values.push(row.value);
                    type.push(row.type);
                    sttype.push(row.sttype);

                    if (row.type == 'Clinical') {
                        clinical_type.push(row.value);
                        year_clin.push(row.year);

                        if (clinical_type_by_year[row.year] > 0) {
                            clinical_type_by_year[row.year] = clinical_type_by_year[row.year] + row.value;
                        }
                        else {clinical_type_by_year[row.year] = row.value;}


                    }
                    else {
                        non_clinical_type.push(row.value);
                        year_non_clin.push(row.year);

                        if (non_clinical_type_by_year[row.year] > 0) {
                            non_clinical_type_by_year[row.year] = non_clinical_type_by_year[row.year] + row.value;
                        }
                        else {non_clinical_type_by_year[row.year] = row.value;}

                    }
                    return null;
                  }
                })
                var sumOfTypes = values.reduce((a, b) => a + b, 0);
                var TotalSumOfTypes = zerovalues.reduce((a, b) => a + b, 0);
                var sumClinical = clinical_type.reduce((a, b) => a + b, 0);
                var sumNonclinical = non_clinical_type.reduce((a, b) => a + b, 0);
                var yearAverage = Object.values(dictionary).reduce((a, b) => a + b, 0) / Object.values(dictionary).length;
                const distinct_submitters = Array.from(new Set(analyticsData.map(({ proj_client }) => proj_client)));

                ;
            return (
                <main>
                    <div className="container center">                        
                        <h4>Data Analytics</h4>
                        <hr>
                        </hr>
                        <h6> Total number of isolates with valid year: <b> { sumOfTypes }   </b>
                        </h6>
                        <h6> Total number of isolates with year equal 0: <b> { TotalSumOfTypes - sumOfTypes }   </b>
                        </h6>
                        <hr>                         
                        </hr></div>
                    <div>
                    <div className="Left Column" style={{width: "50%", float:"left"}}> 
                        <Plot
                            data={[
                                {
                                    x: [sumClinical,sumNonclinical],//values,
                                    y: ['Clinical', 'Non Clinical'],//type,
                                    text: [sumClinical,sumNonclinical],
                                    textposition: 'auto',
                                    hoverinfo: 'none',
                                    type: 'bar',
                                    orientation: 'h',
                                    marker: {
                                        color: 'rgb(160,224,247)',
                                        opacity: 0.9,
                                    },
                                    //color: type,
                                    // marker: { color: 'lightblue' },
                                    //fillcolor: 'lightblue',
                                },
                            ]}
                            layout={{ width: 600, height: 500, title: 'Isolates Count by Sample Type',autosize: true}}
                            useResizeHandler={true}
                            style={{ width: "100%", height: "90%" }}
                        />

                        <Plot
                            data={[
                                {
                                    x: Object.keys(dictionary),//year,
                                    y: Object.values(dictionary), //values,
                                    type: "bar",
                                    text: Object.values(dictionary),
                                    textposition: 'auto',
                                    hoverinfo: 'none',
                                      marker: {
                                            color: 'rgb(160,224,247)',
                                            opacity: 0.9,
                                        },
                                    header: {
                                            values: [["<b>Year</b>"], ["<b>Isolate count</b>"]],
                                            align: "center",
                                            line: {width: 1, color: 'black'},
                                            fill: {color: "grey"},
                                            font: {family: "Arial", size: 12, color: "white"}
                                        },
                                    cells: {
                                            values: [Object.keys(dictionary),Object.values(dictionary)],
                                            align: "center",
                                            line: {color: "black", width: 1},
                                            font: {family: "Arial", size: 11, color: ["black"]}
                                        }
                                },
                            ]}
                            layout={{ width: 600, height: 500, title: 'Isolates Count by Year',autosize: true,
                                xaxis: {range: [2010, 2022],    autorange: false},
                                uniformtext:{minsize:9, 
                                mode:'show'},
                                updatemenus: [
                                    {
                                        buttons: [
                                            {
                                                args: [{'type': "bar"},
                                                    {layout:{xaxis: {range: [2010,2022]},autorange:false}}],  //["type", "bar"],
                                                label:"Bar",
                                                method:"update",
                                            },
                                            {
                                                args:["type", "table"],
                                                label:"Table",
                                                method:"restyle",
                                            }
                                        ],
                                        direction:"down",
                                        //pad:{"r": 10, "t": 10},
                                        showactive:true,
                                        y:1,
                                        yanchor:"top"
                                    },
                                ]}}
                            useResizeHandler={true}
                            style={{ width: "100%", height: "90%" }}
                        />

                    </div>
                    <div className="Right Column" style={{width: "50%", float:"right"}}> 
                    
                         <Plot
                            data={[
                                {
                                    x: Object.keys(clinical_type_by_year),//values,
                                    y: Object.values(clinical_type_by_year),//type,
                                    type: 'bar',
                                    name: 'Clinical',
                                    text: Object.values(clinical_type_by_year),
                                    textposition: 'auto',
                                    hoverinfo: 'none',
                                    marker: {
                                            color: 'rgb(160,224,247)',
                                            opacity: 0.9,
                                    },
                                },
                                {
                                    x: Object.keys(non_clinical_type_by_year),//values,
                                    y: Object.values(non_clinical_type_by_year),//type,
                                    type: 'bar',
                                    name: 'Non clinical',
                                    text: Object.values(non_clinical_type_by_year),
                                    textposition: 'auto',
                                    hoverinfo: 'none',
                                    marker: {
                                            color: 'rgb(0,96,100)',
                                            opacity: 0.9,
                                    },
                                }, 
                            ]}
                            layout={{barmode: 'stack', width: 600, height: 500, title: 'Isolates Count by Sample Type and Year',autosize: true,
                            xaxis: {range: [2010, 2022]},
                            uniformtext:{minsize:9, 
                                mode:'show'},
                            }}
                            
                            useResizeHandler={true}
                            style={{ width: "100%", height: "90%" }}
                        />

                        <Plot
                            data={[
                                {
                                    x: Object.keys(stDictionary),
                                    y: Object.values(stDictionary),
                                    type: 'bar',
                                    text: Object.values(stDictionary),
                                    textposition: 'auto',
                                    hoverinfo: 'none',
                                    marker: {
                                        color: 'rgb(160,224,247)',
                                        opacity: 0.9,
                                    },
                                    transforms: [{
                                        type: 'sort',
                                        target: 'y',
                                        order: 'descending'
                                    }],
                                    fillcolor: 'purple',
                                },
                            ]}
                            layout={{categoryorder: 'total descending', width: 600, height: 500, title: 'Isolates Count by ST Type',autosize: true,
                            uniformtext:{minsize:9, 
                                mode:'show'},
                            xaxis: {type:"category", range: [0, 10],
                                autorange: false},
 }}
                            useResizeHandler={true}
                            style={{ width: "100%", height: "90%" }}
                        />
                    </div>
                    </div>
                </main>
            )

    }

}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        analyticsData: state.analyticsData.payload,
    }
}

export default connect(
    mapStateToProps,
    { getAnalytics },
)(DataAnalytics);