import userQC from "../apis/userQC";
import { GET_ANALYTICS } from "./types";
import eventBus from "../common/EventBus";
import { protectedResources } from '../authConfig';
import { msalInstance } from "../index";

const getToken = async () => {
    const account = msalInstance.getActiveAccount();
  
    if (!account) {
        eventBus.dispatch("logout");
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }
  
    const response = await msalInstance.acquireTokenSilent({
        account: account,
        ...protectedResources.apiTodoList.scopes
    });
  
    return response.accessToken;
  }

export const getAnalytics = () => {
    return async (dispatch, getState) => {
        const token = getToken();

        try {
            const config = {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                data: {},
            };

            const response = await userQC.get(
                '/year_count',
                config,
            );

            console.log("Response", response)

            dispatch({
                type: GET_ANALYTICS,
                payload: response.data,
            });
        }
        catch (error) {
            console.log(error);
            if (error) {
                eventBus.dispatch("logout");
              }
        }
    }
};