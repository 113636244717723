import { GET_ANALYTICS } from "../actions/types";

const analyticsReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_ANALYTICS:
            return {
                ...state,
                payload: action.payload,
            };
        default:
            return state;
    }
};

export default analyticsReducer;