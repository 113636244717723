import postLogin from "../apis/postLogin";
import { LOGIN_SUCCESS, LOGIN_ERROR, SIGNOUT_SUCCESS, REFRESH_TOKEN, REFRESH_ERROR,MSAL_LOGIN_SUCCESS,MSAL_LOGIN_ERROR } from "./types";
import { protectedResources } from '../authConfig';
import { msalInstance } from "../index";
import eventBus from "../common/EventBus";

const getToken = async () => {
    const account = msalInstance.getActiveAccount();

    if (!account) {
        eventBus.dispatch("logout");
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }

    const response = await msalInstance.acquireTokenSilent({
        account: account,
        ...protectedResources.apiTodoList
    });

    return response.accessToken;
}

export const signIn = (formValues) => {
    return async (dispatch) => {
        try {
            const response = await postLogin.post('/login', formValues);
            dispatch({ 
                type: LOGIN_SUCCESS, 
                payload: {
                    token: response.data.token,
                    email: formValues.email,
                    user_role: response.data.user_role,
                    refresh_token: response.data.refresh_token,
                    jwt_validity: response.data.jwt_validity
                }
            });
        }
        catch (error) {
            dispatch({
                type: LOGIN_ERROR,
                error,
            });
        }
    }
}

export const signInMsal = (test) => {
    return async (dispatch) => {
        try {
            const isAuthenticated = this.context.accounts.length > 0;
           
            if (isAuthenticated) {
                // const authTokenMsal = await getToken();

                dispatch({ 
                    type: MSAL_LOGIN_SUCCESS, 
                    // payload: {
                    //     token: authTokenMsal
                    // }
                });
            }
        }
        catch (error) {
            dispatch({
                type: MSAL_LOGIN_ERROR,
                error,
            });
        }
    }
}

export function saveTokenInLocalStorage(tokenDetails){
    localStorage.setItem("user_details", JSON.stringify(tokenDetails))
}

export function runLogoutTimer(dispatch, timer) {
        setTimeout(() => {
            dispatch(signOut())
        }, 9000000)
}

export const signOut = () => {
    return (dispatch, getState) => {
        dispatch({
            type: SIGNOUT_SUCCESS,
        }) 
    }
}

export function getTodayDate() {
        var today = new Date();
        var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
        var time = today.getHours() + ":" + today.getMinutes() ;
        var dateTime = date+' '+time;
        return dateTime;
}

export const refreshToken = () => {
    return async(dispatch,getState) => {
        const jwt_validity = getState().auth.jwt_validity;
        const refresh_token = getState().auth.refresh_token;
        const config = {
            'headers': {
                'Authorization': `Bearer ${refresh_token}`,
            },
            data: {}
        };
            
        var today = new Date();
        var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
        var time = today.getHours() + ":" + today.getMinutes() ;
        var dateTime = date+' '+time;
        console.log("JWTVALIDITY", jwt_validity, dateTime)
        // if(jwt_validity < dateTime){
        try{ 
            const response = await postLogin.post('/refresh', config);

            dispatch({
                type: REFRESH_TOKEN,
                payload: {
                    token : response.data.token, 
                }
            })
        }
        catch (error) {
            console.log("ERORRR", error)
            dispatch({
                type: REFRESH_ERROR,
                error: error.response.status,
            })
        }
    // }
    }
}