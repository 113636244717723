import React from 'react';

import MainComponent from '../MainComponent';

import { connect } from 'react-redux';
import { Redirect } from "react-router";

import Phylocanvas from "phylocanvas";
import contextMenu from 'phylocanvas-plugin-context-menu';
import history from 'phylocanvas-plugin-history';
import metadata from 'phylocanvas-plugin-metadata';
import scalebar from 'phylocanvas-plugin-scalebar';
import chroma from 'chroma-js';

// actions
import { getUserTree } from "../../actions/userTreeActions";
import Loader from "../Loader";

import M from 'materialize-css';

class UserTree extends MainComponent {

    constructor(props) {
        super();
        // create Ref to tree <div>
        this.treeRef = React.createRef();

        // set state for branch length labels and newTreeType
        this.state = {
            disableButton : false,
            showBranchLengthLabels: true,
            newTreeType: 'rectangular',
            userColumn: '',
            columnData: {},
            isolateData: []
        };
    
        // enable phylocanvas plugins
        Phylocanvas.plugin(contextMenu);
        Phylocanvas.plugin(history);
        Phylocanvas.plugin(metadata);
        Phylocanvas.plugin(scalebar);   
        Phylocanvas.setNodeSize = 10;
        Phylocanvas.backColour = true;
        // Phylocanvas.setNodeColourAndShape('1', 'rgb(250, 150, 50)', 'x');

        this.metadata = [
            { data: 'nzfssnumber', title: 'Id' },
            { data: 'species', title: 'Species' },
            { data: 'sampletype', title: 'Sample Type' },
            { data: 'sourcecountry', title: 'Source Country' },
            { data: 'year', title: 'Year' },
            { data: 'mlstcc', title: 'MLSTcc' },
            { data: 'lineage', title: 'Lineage' },
            { data: 'mlst_7', title: 'MLST' },
            { data: 'isolateid', title: 'Isolate id' },
            { data: 'otherisolateid', title: 'Other Isolate Id'},
            { data: 'projectclient', title: 'Submitter' }
        ];

      
    }

    /*
    Custom function that intakes props (data) of tree, <div> id and tree type,
    and loads phylocanvas tree.
    */
    drawUserTree(treeProps, divId, treeType, columnName, columnData) {
        // reset canvas before loading the tree
        const node = this.treeRef.current;
        node.innerHTML = "";

        // create tree canvas on the <div> id
        const tree = Phylocanvas.createTree(divId);

        // set tree type
        tree.setTreeType(treeType);

        // align labels
        tree.alignLabels = false;

        // show Labels
        // tree.showLabels = true;

        //show Leaf Labels
        // tree.showLeafLabels = true;

        // set tree size
        tree.size= { width: 400, height: 700 };

        //set tree interactive to true
        tree.interactive = true;

        tree.nodeSize= 24;
        // tree.nodeShape = Phylocanvas.Shapes.Square;
        tree.fillColour = "rebeccapurple";

        // branch length labels
        tree.showBranchLengthLabels = this.state.showBranchLengthLabels;
        tree.branchLengthLabelPredicate = () => this.state.showBranchLengthLabels;

        // metadata
        tree.on('beforeFirstDraw', function () {

            for (let i = 0; i < tree.leaves.length; i++) {
                let treeData = {}
                for (let cdata in columnData) {
                    // console.log("TreeData", columnData)
                    if (tree.leaves[i].label in columnData[cdata]) {
                        // add treeData if label is in the columns fetched from isolates table
                        treeData[cdata] = {
                            // add labels to the multi columns
                            label: columnData[cdata][tree.leaves[i].label]['label'],
                            // add colours to multi columns
                            colour: columnData[cdata][tree.leaves[i].label]['colour']
                        };
                    }
                }
                tree.leaves[i].data = treeData;
            }
        });

        // load tree from newick string data in props
        tree.load(treeProps);
    }

    async componentDidMount() {
        // set to true when the component is mounted 
        this.mounted = true
        // check if userTree exists and call custom draw function
        const { auth, userTree } = this.props;

        // if userTree already exists in the state props
        if (auth.email) {
            if (userTree) {
                // call custom draw function by passing existing userTree => (userTree, <div>, "type_of_tree") (rectangular, circular, etc.)
                // tree.destroy();

                this.drawUserTree(userTree.tree, this.treeRef.current.id, this.state.newTreeType, this.state.userColumn, this.state.columnData);
            } else {
                // call props userTree and get full tree
                await this.props.getUserTree();

                // call custom draw function by calling existing props userTree => (userTree, <div>, "type_of_tree") (rectangular, circular, etc.)
                //this.drawUserTree(this.props.userTree.tree, this.treeRef.current.id, this.state.newTreeType, this.state.userColumn, this.state.columnData);
            }
        }

        // await this.props.getUserIsolates();

        M.AutoInit();
    }

    componentDidUpdate() {
        if (this.state) {
            // get current node reference for <div>
            const node = this.treeRef.current;

            // check if userTree exists and call custom draw function
            const { userTree } = this.props;

            // if userTree already exists in the state props
            if (userTree) {
                // call custom draw function by passing existing userTree => (userTree, <div>, "type_of_tree") (rectangular, circular, etc.)
                this.drawUserTree(userTree.tree, node.id, this.state.newTreeType, this.state.userColumn, this.state.columnData);
            } 
            // else {
            //     // call custom draw function by calling existing props userTree => (userTree, <div>, "type_of_tree") (rectangular, circular, etc.)
            //     this.drawUserTree(userTree.tree, node.id, this.state.newTreeType, this.state.userColumn, this.state.columnData);
            // }
        }
    }

    //  unmount the userTree
    componentWillUnmount() {
        //this.drawUserTree()
        //set the this.mounted to false when unmounting the component 
        this.mounted = false;
        this.props.getUserTree();
    }

    updateTreeType(treeType) {
        // set state with the new tree type when dropdown changes
        this.setState({
            'newTreeType': treeType,
        })
    }

    treeTypeSelectChange = (event) => {
        // get target from the event which is <select>
        const userTreeType = event.target.value;
        // call function to setState with the new user tree type
        this.updateTreeType(userTreeType);
    }

    // on toggle switch change
    toggleBranchLengthLabels = () => {
        const toggleStatus = this.state.showBranchLengthLabels;

        // set false if true, else true if false
        if (toggleStatus) {
            this.setState({
                showBranchLengthLabels: false,
            });
        } else {
            this.setState({
                showBranchLengthLabels: true,
            });
        }

        // call drawtree function to redraw the tree again
        // this.drawUserTree(this.props.userTree.tree, this.treeRef.current.id, this.state.newTreeType, this.state.userColumn, this.state.columnData);
    }

    // clear custom user isolates if exists
    reloadTree = () => {
        if(!this.treeRef){
            this.loader = true
        }
        this.loader = false
        
        // call action with empty parameter i.e. fetches all isolates and full tree
        this.props.getUserTree();
    }

    // function to initiate chroma.js colour scales on column change
    selectedColumnChange = (event) => {
        // this.props.getUserIsolates();
        let data = {};
        let vals = [];


        const { userTree ,isolates, newcolumndata} = this.props;

        // gets the selected items from the dropdown
        let selectedColumns = Array.from(event.target.selectedOptions, option => option.value);

        // get the label values for the selected columns from the dropdown 
        selectedColumns.forEach((col) => {
            isolates.forEach((item) => {
                vals.push(item[col]);
            });
        });

        // get only unique labels from each of the selected columns
        vals = [...new Set(vals)];

        // generate colour scale for set of items in isolate columns
        let colours = chroma.scale(['#fafa6e', '#2A4858']).mode('lch').colors(vals.length);
        let multiColumnArray;
        let multicolumnObj = {};
        selectedColumns.forEach((col) => {
           multiColumnArray = [];
            isolates.forEach((item) => {
                data = {
                    colour: colours[vals.indexOf(item[col])],
                    label: item[col],
                    nzfssnumber: item['nzfssnumber']
                };
                multiColumnArray.push(data);
            });
            multicolumnObj[col] = this.convertToObject(multiColumnArray);

        });

        // add user column and column data (labels, colours) to state
        this.setState({
            userColumn: event.target.selectedOptions,
            columnData: multicolumnObj
        });

        // draw tree with user column and column data
        this.drawUserTree(userTree.tree, this.treeRef.current.id, this.state.newTreeType, this.state.userColumn, this.state.columnData);
    }
      
    /* custom function to convert the array of objects to object of objects */

    convertToObject(columnArray) {
        const arrayToObject = (columnArray, key) => {
            const initialValue = {};
            return columnArray.reduce((obj, item) => {
                return {
                    ...obj,
                    [item[key]]: item,
                };
            }, initialValue);
        }

        const columnObject = arrayToObject(columnArray, 'nzfssnumber')
        return columnObject;
    }

    render() {
        const { auth, userTree, newcolumndata } = this.props;

        if (!auth.email) return <Redirect to='/login' />

        
        // const isolateData = [
        //     { data: 'nzfssnumber', title: 'Id' },
        //     { data: 'species', title: 'Species' },
        //     { data: 'sampletype', title: 'Sample Type' },
        //     { data: 'sourcecountry', title: 'Source Country' },
        //     { data: 'year', title: 'Year' },
        //     { data: 'mlstcc', title: 'MLSTcc' },
        //     { data: 'lineage', title: 'Lineage' },
        //     { data: 'mlst_7', title: 'MLST' }
        // ];
        
        if (userTree) {
            return (
                <main>
                    <div className="container">
                        <div className="row">
                            <div className="col s2">
                                <label>Select tree type</label>
                                <select id="treeTypeSelect" className="browser-default" onChange={this.treeTypeSelectChange}>
                                    <option value="rectangular" defaultValue>Rectangular</option>
                                    <option value="circular">Circular</option>
                                    <option value="radial">Radial</option>
                                    <option value="diagonal">Diagonal</option>
                                    <option value="hierarchical">Hierarchical</option>
                                </select>
                            </div>
                            <div className="col s4 center">
                                <div className="switch valign">
                                    <p>
                                        <label>
                                            <input type="checkbox" onChange={this.toggleBranchLengthLabels} /> Toggle here to hide number of Allele differences
                                            <span className="lever"></span>
                                        </label>
                                    </p>
                                    <p>Show/Hide branch labels</p>
                                </div>
                            </div>
                            <div className="col s3 valign-wrapper">
                                <p>
                                    <button className="btn-large" onClick={this.reloadTree}>
                                        <i className="material-icons left">refresh</i>Reload full tree
                                    </button>
                                </p>
                            </div>
                            <div className="col s3">
                                <label>Select Columns (Load Isolates Before using this option)</label>
                                <select multiple name="selectColumns" onChange={this.selectedColumnChange}>
                                    {/* {isolates ?
                                        Object.keys(isolates[0]).map((item) => {                                           
                                            if (item !== 'record_id') {
                                                return (
                                                    <option key={item} value={item}>{item}</option>
                                                );
                                            }
                                            return false;
                                        }) : null
                                    } */}
                                    { newcolumndata ? 
                                        newcolumndata.map(item => {
                                            if(item.title !== 'Id' & item.data !== null)
                                            return (
                                                <option key={item.data} value={item.data}>{item.title}</option>
                                            );  
                                        }):  this.metadata.map(item => {
                                            if(item.title !== 'Id' )
                                            return (
                                                <option key={item.data} value={item.data}>{item.title}</option>
                                            );  
                                        }) 
                                    }
                                </select>
                            </div>
                        </div>
                        {
                            this.loader ? <Loader></Loader> : <div></div>
                        }
                        <div className="row">
                            <div
                                id="phyloTreeDiv"
                                ref={this.treeRef}
                                style={{ height: '80vh' }}
                                className="col s11"
                            />
                        </div>
                    </div>
                </main>
            );
        } else {
            return (
                <main>
                    <Loader></Loader>
                </main>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        userTree: state.userTree.payload,
        isolates: state.isolates.payload,
        newcolumndata: state.userTree.newColumnData
    }
}

export default connect(
    mapStateToProps,
    { getUserTree },
)(UserTree);
