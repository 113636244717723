import React, {useState} from 'react';
import 'materialize-css/dist/css/materialize.min.css';
import Routes from '../Routes';
import { MsalProvider } from "@azure/msal-react";


class App extends React.Component {

  constructor(props) {
    super(props)
    this.state = { auth : null}
  }

  componentDidMount(){
    fetch('authConfig.json').then(response => {
      console.log("Auth", response)
      response.json().then(auth => {
          this.setState({auth: auth});
      })
  }) 
  }

  render() {
    return (
      <MsalProvider instance={this.props.instance}>
          <Routes></Routes>
      </MsalProvider>    
    );
  }
}

/**
 * msal-react is built on the React context API and all parts of your app that require authentication must be 
 * wrapped in the MsalProvider component. You will first need to initialize an instance of PublicClientApplication 
 * then pass this to MsalProvider as a prop. All components underneath MsalProvider will have access to the 
 * PublicClientApplication instance via context as well as all hooks and components provided by msal-react. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
//  const App = ({ instance }) => {
//   return (
//       <MsalProvider instance={instance}>
//         <Routes></Routes>
//       </MsalProvider>
//   );
// }


export default App;
