import userAccount from "../apis/userAccount";
import { GET_USERS, CREATE_USER, CREATE_USER_ERROR, DELETE_USER, DELETE_USER_ERROR, UPDATE_USER_ORG, UPDATE_USER_ERROR } from "./types";
import eventBus from "../common/EventBus";
import { protectedResources } from "../authConfig";
import { msalInstance } from "../index";

const getToken = async () => {
    const account = msalInstance.getActiveAccount();

    if (!account) {
        eventBus.dispatch("logout");
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called."); 
    }

    const response = await msalInstance.acquireTokenSilent({
        account: account,
        ...protectedResources.apiTodoList.scopes
    });

    return response.accessToken;
}

export const getUserAccount = () => {

    return async (dispatch, getState) => {
        const token =await  getToken();

        try {
            const config = {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                data: {},
            };
            
            const response = await userAccount.get(
                '/users',
                config,
            );
            dispatch({
                type: GET_USERS,
                users: response.data,
            });
        }
        catch (error) {
            console.log(error);
            if (error) {
                eventBus.dispatch("logout");
              }
        }
    }
};

export const createUser = (formValues) => {
    return async (dispatch, getState) => {
        const token = await getToken();

        const config = {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        };

        await userAccount.post(
            '/create_account',
            formValues,
            config,
        ).then(response => {
            dispatch({
                type: CREATE_USER,
                creationError: response.data,
            });
        }).catch(error => {
            dispatch({
                type: CREATE_USER_ERROR,
                error: error.response.data.msg
            });
            if (error) {
                eventBus.dispatch("logout");
              }
        });
    }
};

export const deleteUser = (deleteObj) => {
    return async (dispatch, getState) => {
        const token = await getToken();
        const config = {
            data: { email: deleteObj.email },
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        };
        await userAccount.delete(
            '/delete_account',
            config,
        ).then(response => {
            dispatch({
                getUserAccount,
                type: DELETE_USER,
                payload: response.data.user.email,
            });
        }).catch(error => {
            dispatch({
                type: DELETE_USER_ERROR,
                error: error.response.data.msg
            });
            if (error) {
                eventBus.dispatch("logout");
              }
        });
    }
}

export const updateUserOrg = (formValues) => {

    console.log("FormValues", formValues)
    return async (dispatch, getState) => {
        const token = await getToken();;

        const config = {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        };

        await userAccount.post(
            '/update_user_organisations',
            formValues,
            config,
        ).then(response => {
            dispatch({
                type: UPDATE_USER_ORG,
                creationError: response.data,
            });
        }).catch(error => {
            dispatch({
                type: UPDATE_USER_ERROR,
                error: error.response.data.msg
            });
            if (error) {
                eventBus.dispatch("logout");
              }
        });
    }
};